import React from "react";

// CSS
import "./Dashboard.scss";

function Dashboard() {
  return <h1>Staff Dashboard</h1>;
}

export default Dashboard;
